/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */

try {
    window.$ = window.jQuery = require("jquery");
    window.bootstrap = require("bootstrap5/dist/js/bootstrap.bundle");
} catch (e) { }

let _window = $(window),
    body = $('body');

$.ajaxSetup({
    headers: {
        "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
        "X-Requested-With": "XMLHttpRequest",
    },
});

window.onSubmitCaptcha = function (token) {
    $("#reCaptchaForm")
        .find("button[type=submit]")
        .prop("disabled", true)
        .append('<i class="fad fa-spinner-third fa-spin ms-1"></i>');
    $("#reCaptchaForm").trigger("submit");
};

$("input[type=tel], input[inputmode=numeric]").on("input", function (e) {
    $(this).val(
        $(this)
            .val()
            .replace(/[^0-9]/g, "")
    );
});

$("form")
    .not("#reCaptchaForm")
    .on("submit", function (event) {
        if ($(this).find(":invalid").length) {
            event.preventDefault();
            $(this).find(":invalid").first().focus();
        } else {
            $(this)
                .find("button[type=submit]")
                .prop("disabled", true)
                .append('<i class="fad fa-spinner-third fa-spin ms-1"></i>');
        }
    });

$('#logout').on('click', function () {
    $('#logout-form').submit();
});

$('[data-loading]').on('click', (e) => {
    $(e.currentTarget).prop('disabled', true).addClass('pe-none').append('<i class="fad fa-spinner-third fa-spin ms-1"></i>');
});

/*      header
/*----------------------------------------*/

let headerWrap = $('.header-wrap'),
    headerWrapInner = $('.header-wrap-inner'),
    headerWrapInnerHeight = headerWrapInner.outerHeight();

_window.on('resize', () => {
    headerWrapInnerHeight = headerWrapInner.outerHeight();
});

_window.on('load scroll resize', () => {
    let headerWrapHeight = headerWrap.outerHeight(),
        headerWrapOffsetTop = headerWrap.offset().top + headerWrapHeight;

    function stickyHeader() {
        let scrollTop = _window.scrollTop();

        if (scrollTop > headerWrapOffsetTop) {
            headerWrap.css('padding-top', `${headerWrapInnerHeight}px`);
            headerWrapInner.addClass('sticky');

            setTimeout(() => {
                headerWrapInner.addClass('show');
            });

            return;
        }

        headerWrap.css('padding-top', 0);
        headerWrapInner.removeClass('sticky show');
    }

    stickyHeader();
});

/*      menu dropdown arrow
/*----------------------------------------*/

let megaMenuItem = $('.mega-menu > li'),
    subMenuDropdown = $('.sub-menu > .dropdown'),
    sidebarMenuSubMenu = $('.sidebar-menu .sub-menu');

function menuDropdownArrow(parentSelector, childSelector) {
    parentSelector.each(function () {
        let self = $(this);

        if (self.children().length > 1) {
            self.children(`${childSelector}`).append('<i class="fal fa-angle-right"></i>');
        }
    });
}

menuDropdownArrow(subMenuDropdown, 'a');
menuDropdownArrow(megaMenuItem, '.menu-item');

/*      category tree
/*----------------------------------------*/
$('.browse-categories li').each((i, li) => {
    if ($(li).children('ul').length > 0) {
        $(li).addClass('parent');
    }
});

let filterCategoriesLink = $('.browse-categories li.parent > a');
let parentUls = $('.browse-categories li.active').parentsUntil('.browse-categories', 'ul');

filterCategoriesLink.before('<i class="fal fa-angle-right"></i>');
parentUls.show().siblings('i').addClass('open');

$('.browse-categories li i').on('click', (e) => {
    $(e.currentTarget).toggleClass('open').siblings('ul').slideToggle(300);
})

$('.browse-categories li.parent i').each((i, arrow) => {
    if ($(arrow).siblings('.first-sub').length > 0) {
        $(arrow).addClass('open');
    }
});

//dashboard menu
$('.account-sidebar li').each((i, li) => {
    if ($(li).children('ul').length > 0) {
        $(li).addClass('parent');
    }
});

let accountSidebarLink = $('.account-sidebar li.parent > a');
let accountParentUls = $('.account-sidebar li.active').parentsUntil('.account-sidebar', 'ul');

accountSidebarLink.after('<i class="fal fa-angle-right"></i>');
accountParentUls.show().siblings('i').addClass('open');

$('.account-sidebar li > i, .account-sidebar li > .link').on('click', (e) => {
    $(e.currentTarget).siblings('i').toggleClass('open').siblings('ul').slideToggle(300);
})

/*      search product
/*----------------------------------------*/
$('#sort_by, #per_page').on('change', function () {
    $('.query_sort_by').val($('#sort_by').val());
    $('.query_per_page').val($('#per_page').val());

    $('#products-search').submit();
});

$('.product_category').on('click', function () {
    $('.query_sort_by').val($('#sort_by').val());
    $('.query_per_page').val($('#per_page').val());
    $('.query_product_category').val($(this).data('slug'));

    $('#products-search').submit();
});

$('#start-search').on('click', function () {
    $('#products-search').submit();
})

/*      input number quantity
/*----------------------------------------*/
$('.plus').on('click', function () {
    let el_id = '#' + $(this).data('id');

    var quantity = $(el_id).val();
    var max = $(el_id).attr('max');

    var result = parseInt(quantity);
    result++;

    if (result >= max) {
        result = max;
    }

    $(el_id).val(result).change();
});

$('.minus').on('click', function () {
    let el_id = '#' + $(this).data('id');

    var quantity = $(el_id).val();
    var result = parseInt(quantity);
    result--;

    if (result <= 0) {
        result = 1;
    }

    $(el_id).val(result).change();
});

$('.input-text.qty').on('change', function () {
    var quantity = $(this).val();
    var max = $(this).attr('max');
    var min = $(this).attr('min');

    var result = parseInt(quantity);

    if (result <= min) {
        result = min;
    }

    if (result >= max) {
        result = max;
    }

    $(this).val(result);
});

/*      overlay
/*----------------------------------------*/

let overlay = $('.overlay');

/*      sidebar cart
/*----------------------------------------*/

let headerCart = $('.header-cart'),
    sidebarCart = $('.sidebar-cart-wrap');

headerCart.on('click', (e) => {
    e.stopPropagation();

    overlay.addClass('active');
    sidebarCart.addClass('active');
});

sidebarCart.on('click', (e) => {
    e.stopPropagation();
});

body.on('click', () => {
    overlay.removeClass('active');
    sidebarCart.removeClass('active');
});

$('.number-only').on('keyup', function () {
    let new_input = $(this).val().replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1').replace(/^0[^.]/, '0');
    $(this).val(new_input);
});

function initMobileMenu() {
    document.addEventListener(
        "DOMContentLoaded", () => {
            const menu = new MmenuLight(
                document.querySelector("#mobileMenu"),
                "(max-width: 1160px)",
            );

            $("#mobileMenu").addClass("mm");

            const navigator = menu.navigation({
                title: 'Menu',
                selected: "active",
            });

            const drawer = menu.offcanvas({
                position: "right",
                move: true,
                blockPage: true,
            });

            $('a[href="#openMenu"]')
                .on('click', (evnt) => {
                    evnt.preventDefault();
                    drawer.open();
                });
        }
    );
}

initMobileMenu();


window.addEventListener("pageshow", function (event) {
    var historyTraversal = event.persisted ||
        (typeof window.performance != "undefined" &&
            window.performance.navigation.type === 2);
    if (historyTraversal) {
        // Handle page restore.
        window.location.reload();
    }
});
